import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate } from 'react-router-dom'

import { Button, Center, HStack, Text, useToast } from '@chakra-ui/react'

import LoadingStrateegia from 'modules/common/components/loading'
import { useMe } from 'modules/user/hooks'

type Props = {
	children: ReactNode
}

export const ProtectionRouteUnvalidatedEmail = ({ children }: Props) => {
	const { user } = useMe()
	const toast = useToast()
	const navigate = useNavigate()
	const { t } = useTranslation()

	if (!user) {
		return (
			<Center>
				<LoadingStrateegia />
			</Center>
		)
	}

	if (!user?.email_validated) {
		toast.closeAll()
		toast({
			title: t('common:emailConfirmation.toastTitle'),
			description: (
				<HStack>
					<Text>
						<Button
							textDecoration='underline'
							color='white'
							variant='link'
							onClick={() => {
								navigate('/dashboard/settings?openEmailValidation=true')
								toast.closeAll()
							}}
						>
							{t('buttons:clickHere')}
						</Button>{' '}
						{t('common:emailConfirmation.toastDescription')}
					</Text>
				</HStack>
			),
			status: 'warning',
			isClosable: true,
			duration: 4000
		})
		return <Navigate to='/dashboard' />
	}

	return <>{children}</>
}
