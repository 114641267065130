import { fetch } from 'config'

type Params = {
	verified: boolean
}

export const verifyEmail = ({
	email,
	code
}: {
	email: string
	code: string
}) => {
	return fetch<Params>({
		url: '/users/v1/user/email-validation/verify',
		method: 'POST',
		data: { email, code }
	}).then(({ data }) => data)
}
