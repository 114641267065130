import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
	Box,
	Button,
	HStack,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useToast,
	VStack
} from '@chakra-ui/react'

import { sendGAEvent } from 'config/trackers/google-analytics'

import { useMe } from 'modules/user/hooks'

import { IconStCoin } from '../../icons'

type Props = {
	isOpen: boolean
	onClose: () => void
	amount?: number
	openBuyCredits: () => void
}

export const EmptyCredits = ({
	isOpen,
	onClose,
	amount = 0,
	openBuyCredits
}: Props) => {
	const { t } = useTranslation()
	const { user } = useMe()
	const navigate = useNavigate()
	const toast = useToast()

	const validateUserToast = () => {
		return toast({
			title: t('common:emailConfirmation.toastTitle'),
			description: (
				<HStack>
					<Text>
						<Button
							textDecoration='underline'
							color='white'
							variant='link'
							onClick={() => {
								navigate('/dashboard/settings?openEmailValidation=true')
								toast.closeAll()
							}}
						>
							{t('buttons:clickHere')}
						</Button>{' '}
						{t('common:emailConfirmation.toastDescription')}
					</Text>
				</HStack>
			),
			status: 'warning',
			isClosable: true,
			duration: 3000
		})
	}

	const handleBuyCredits = () => {
		if (!user?.email_validated) {
			toast.closeAll()
			return validateUserToast()
		}

		openBuyCredits()
		sendGAEvent('click_button_buy_credits_nocredits_starting_point')
	}

	return (
		<Modal size='xl' isCentered isOpen={isOpen} onClose={onClose}>
			<ModalOverlay bg='rgba(35, 39, 53, 0.40)' backdropFilter='blur(5px)' />
			<ModalContent pt={4} px={2} pb={2}>
				<ModalCloseButton />
				<ModalHeader>
					<Text fontSize='lg' fontWeight='bold'>
						{t('credits:empty.title')}
					</Text>
				</ModalHeader>
				<ModalBody>
					<VStack gap={4}>
						<HStack>
							<Icon w={8} h={8} as={IconStCoin} />
							<Text fontSize='2xl' fontWeight='bold'>
								{amount}
							</Text>
						</HStack>
						<Box>
							<Trans i18nKey='credits:empty.text' components={[<strong />]} />
						</Box>
					</VStack>
				</ModalBody>
				<ModalFooter mt={2}>
					<HStack>
						<Button fontSize='sm' onClick={onClose}>
							{t('buttons:maybeLater')}
						</Button>
						<Button
							fontSize='sm'
							onClick={handleBuyCredits}
							colorScheme='extra_color_scheme_button'
						>
							{t('buttons:buyCredits')}
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
