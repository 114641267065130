import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Progress, Text, HStack } from '@chakra-ui/react'

import { consensusColor } from '../../helpers'
import { ConvergenceOption } from '../../types'

type Props = {
	option: ConvergenceOption
}

export const BeliefOptionResult: React.FC<Props> = ({ option }) => {
	const average = Math.round((option.average || 0) * 100)
	const consensus = Math.round((option.consensus || 0) * 100)

	const { t } = useTranslation()
	return (
		<Box bgColor='purple.1' borderRadius='xl' padding={4} _notFirst={{ mt: 4 }}>
			<HStack justify='space-between'>
				<Text minW='10rem' fontSize='sm' fontWeight='medium'>
					{option.text}
				</Text>
				<HStack fontSize='sm'>
					<Text>{t('map:points.convergence.beliefInfo')} </Text>
					<Text fontSize='sm' fontWeight='semibold'>
						{average}%
					</Text>
				</HStack>
			</HStack>
			<Progress
				value={average}
				borderRadius='full'
				colorScheme='purple'
				bg='purple.50'
				mt={2}
			/>
			{option.consensus !== null && (
				<Text
					fontSize='sm'
					color={consensusColor(consensus)}
					mt={4}
					fontWeight='bold'
				>
					{t('map:points.convergence.iccInfo')} {consensus}%
				</Text>
			)}
		</Box>
	)
}
