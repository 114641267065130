import { useContext, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { Tokens, persistTerms } from 'config'
import Api from 'data/api'

import { CustomSchemeButton } from 'modules/common/components/buttons'
import { useQueryParams } from 'modules/common/hooks'
import { parseLanguage } from 'modules/common/utils/change-language'
import { getDomainFromEmail } from 'modules/common/utils/get-domain-from-email'
import { useGetPrivateProviders } from 'modules/login/hooks/use-get-private-provider'
import { useGetPublicProviders } from 'modules/login/hooks/useGetPublicProviders'
import { Provider } from 'modules/login/types'
import { CreateUserParams } from 'modules/user/types'

import { SignUpContext } from '..'

import {
	CreatWithProvider,
	CreateUserWithProvider
} from '../usecases/create-with-provider'
import { concatProviders } from '../utils/concat-provider'
import {
	getToKnowValidation,
	signUpProviderValidation,
	signUpValidation
} from '../validators'
import { ciaValidation } from '../validators/cia-de-talentos-external-validation'
import { SignupWithExternalToken } from './cia-de-talentos/signup'
import { ContinueButton } from './continue-button'
import { GetToKnow } from './get-to-know'
import { SignUpData } from './signup-data'
import { SignupWithProvider } from './signup-data-with-provider'

interface SubmitValues extends CreateUserParams {
	confirmPassword: string
}

interface SubmitValuesWithProvider extends CreateUserWithProvider { }

export const Form = ({ previousURL }: { previousURL: string }) => {
	const { t, i18n } = useTranslation()
	const navigate = useNavigate()
	const query = useQueryParams()
	const email = query.get('email') as string
	const name = query.get('name') as string
	const providerId = query.get('providerId') as string
	const externalId = query.get('externalId') as string
	const payload = query.get('payload') as string
	const accountId = query.get('account_id') as string
	const accessToken = query.get('accessToken') as string
	const withProvider = query.get('action') === 'withProvider'
	const isExternalToken = query.get('action') === 'externalToken'
	const sub = query.get('sub') as string
	const previousUrlParam = query.get('previousUrl')
	const { data: publicProviders } = useGetPublicProviders()
	const { data: privateProvider } = useGetPrivateProviders(
		getDomainFromEmail(email || '')
	)

	const { showMoreData, setShowMoreData, setLogoAsLink } =
		useContext(SignUpContext)

	const [step, setStep] = useState(0)

	const validation = [
		withProvider
			? signUpProviderValidation()
			: isExternalToken
				? ciaValidation()
				: signUpValidation(),
		getToKnowValidation()
	]

	const currentValidation = validation[step]

	const methods = useForm({
		mode: 'onChange',
		resolver: yupResolver(currentValidation)
	})

	const { isValid, isSubmitting, errors } = methods.formState

	const hasErrors = Object.values(errors).some(Boolean)

	const handleClick = () => {
		if (!isExternalToken) {
			setLogoAsLink(false)
			setStep(step === 0 ? step + 1 : step)
			setShowMoreData.on()
		}
	}

	const { pathname, search } = useLocation()
	const current = `${pathname}${search}`
	const ignoredPaths = ['/signin', '/', '/signup', current]
	const redirectURL = !ignoredPaths.includes(previousURL)
		? previousURL
		: '/dashboard'
	const tenantUrl = `${window.location.origin}/auth/callback`

	const onSubmit = async (values: Omit<SubmitValues, 'language'>) => {
		const { email, password, term_accepted } = values

		try {
			await Api.Users.create({
				...values,
				language: parseLanguage(i18n.language),
				aux_id: sub
			})

			const account = await Api.Authentication.auth({
				email,
				password
			})

			Tokens.set(account, false)
			persistTerms.set(term_accepted)

			if (isExternalToken) {
				navigate(`/import?account_id=${accountId}&payload=${payload}`)
				return
			}

			navigate('/email-validation/send')
		} catch (err) {
			setShowMoreData.off()
			methods.setError('email', {
				type: 'validate',
				message: t('errors:emailInUseError')
			})
		}
	}

	const combinedProviders: Provider[] = concatProviders(
		publicProviders as Provider[],
		privateProvider as Provider
	)

	const providerSelected = combinedProviders?.find((prov) => {
		if (prov.id) {
			return prov.id === providerId
		}
		return false
	})

	const onSubmitWithProvider = async (
		values: Omit<SubmitValuesWithProvider, 'language'>
	) => {
		try {
			await CreatWithProvider({
				...values,
				provider_id: providerId,
				external_id: externalId,
				access_token: accessToken,
				language: parseLanguage(i18n.language)
			})

			const stateObject = {
				providerId,
				urlCallback: tenantUrl,
				previousUrl: previousUrlParam || redirectURL
			}

			const encodedState = encodeURIComponent(JSON.stringify(stateObject))

			if (providerSelected) {
				const providerAuthUrl = `${providerSelected.authorization_endpoint
					}?client_id=${providerSelected.client_id
					}&scope=${providerSelected.scope.join(
						' '
					)}&redirect_uri=${tenantUrl}&response_type=code&state=${encodedState}&login_hint=${email}`
				window.location.href = providerAuthUrl
			}
		} catch (error) {
			setShowMoreData.off()
		}
	}

	const handleFormSubmit = async (values: any) => {
		if (withProvider) {
			const providerValues = values as Omit<
				SubmitValuesWithProvider,
				'language'
			>
			await onSubmitWithProvider(providerValues)
		} else {
			const userValues = values as Omit<SubmitValues, 'language'>
			await onSubmit(userValues)
		}
	}

	return (
		<FormProvider {...methods}>
			<form onSubmit={methods.handleSubmit(handleFormSubmit)}>
				{showMoreData === false && (
					<>
						<SignupForm
							withProvider={withProvider}
							isExternalToken={isExternalToken}
							email={email}
							name={name}
							previousURL={previousURL}
						/>
						<ContinueButton
							onClick={handleClick}
							isDisabled={!isValid || hasErrors}
						/>
					</>
				)}
				{showMoreData === true && !isExternalToken && (
					<>
						<GetToKnow />
						<CustomSchemeButton
							type='submit'
							mt={8}
							colorScheme='pink'
							width='full'
							isDisabled={!isValid || isSubmitting}
						>
							{t('buttons:signUp')}
						</CustomSchemeButton>
					</>
				)}
			</form>
		</FormProvider>
	)
}

const SignupForm = ({
	withProvider,
	isExternalToken,
	email,
	name,
	previousURL
}: {
	withProvider: boolean
	isExternalToken: boolean
	email: string
	name: string
	previousURL: string
}) => {
	if (withProvider) {
		return <SignupWithProvider email={email} name={name} />
	} else if (isExternalToken) {
		return <SignupWithExternalToken email={email} name={name} />
	}

	return <SignUpData previousURL={previousURL} />
}
