import { useQuery, useQueryClient } from 'react-query'

import { sessionManager } from 'config/session-manager'
import Api from 'data/api'

import { User } from 'modules/user/types'

import { userKeys } from '../query'

const AUTH_ROUTES = ['/signin', '/signup', '/email-validation']

export const useMe = () => {
	const queryClient = useQueryClient()
	const hasSession = sessionManager.hasSession()
	const currentPath = window.location.pathname
	const isAuthRoute = AUTH_ROUTES.includes(currentPath)

	const { data, isLoading } = useQuery(userKeys.me(), () => Api.Users.getMe(), {
		enabled: hasSession && !isAuthRoute
	})

	const hasEmailNotValidatedByDeadline =
		!isAuthRoute && data?.force_email_validation

	const hasInvalidSession = !isAuthRoute && !hasSession

	if (hasEmailNotValidatedByDeadline || hasInvalidSession) {
		sessionManager.endSession()
	}

	return {
		user: data,
		isLoading,
		mutateUser: (user: User | Partial<User>) =>
			queryClient.setQueryData('me', user)
	}
}
