import { fetch } from 'config'

type Params = {
	id: string
	email: string
	startedAt: string
}

export const sendEmailValidation = () => {
	return fetch<Params>({
		url: '/users/v1/user/email-validation/request',
		method: 'POST'
	}).then(({ data }) => data)
}
