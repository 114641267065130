import React, { lazy, useEffect } from 'react'
import {
	BrowserRouter,
	Route,
	Routes,
	useLocation,
	useNavigate
} from 'react-router-dom'

import { AxiosError } from 'axios'
import { useResponseInterceptor } from 'config/fetch'
import { sessionManager } from 'config/session-manager'
import { sendPageView } from 'config/trackers/google-analytics'
import { MakeLogin } from 'factories/pages'
import { PrivateRoute, RecoverSessionRoute } from 'routes'

import { AssistantChatFreeModal } from 'modules/assistants/components/assistant-chat-free'
import { useAssistantFreeChat } from 'modules/assistants/hooks/use-assistant-free-chat'
import AssistantsRoutes from 'modules/assistants/routes'
import { EmailValidation } from 'modules/common/components/validate-email/email-validation'
import { SendEmail } from 'modules/common/components/validate-email/send-email'
import { DashboardLayout } from 'modules/common/templates'
import { EditorLayout } from 'modules/common/templates/dashboard-layout/dashboard-layout'
import DashboardRoutes from 'modules/dashboard/routes'
import { MapRedirect } from 'modules/journeys/components/map-redirect'
import { PointDetail } from 'modules/journeys/components/point-detail'
import JourneysRoute from 'modules/journeys/routes'
import LabsRoutes from 'modules/labs/routes'
import { CallBackProvider } from 'modules/login/components/providerCallback'
import PageSignup from 'modules/signup'
import { ExternalTokenCallback } from 'modules/signup/components/cia-de-talentos/external-token-callback'
import ToolsRoutes from 'modules/tools/routes'
import ProfileRoute from 'modules/user/routes'

import { Loading } from './loading'
import { V1Redirect } from './V1Redirect'

const JourneyEditor = lazy(
	() => import('modules/journeys/pages/journey-editor')
)
const NonAccessibleJourney = lazy(
	() => import('modules/journeys/pages/non-accessible-journey')
)
const CompatibleRoutes = lazy(
	() => import('modules/journeys/components/compatible-routes')
)
const Home = lazy(() => import('modules/home'))
const Applets = lazy(() => import('modules/applets'))
const Settings = lazy(() => import('modules/user/pages/settings'))
const PublicLink = lazy(() => import('modules/journeys/pages/public-link'))
const TermsPage = lazy(() => import('modules/common/pages/term'))

const AppRouter: React.FC = () => {
	const { pathname, search } = document.location
	const previousURL =
		sessionStorage.getItem('previousURL') || `${pathname}${search}`

	return (
		<BrowserRouter>
			<Routes>
				<Route path='/*' element={<RoutesShell previousURL={previousURL} />} />
			</Routes>
		</BrowserRouter>
	)
}

export default AppRouter

const RoutesShell = ({ previousURL }: { previousURL: string }) => {
	const location = useLocation()
	const navigate = useNavigate()
	const { isOpenAssistantChatFree, onCloseAssistantChatFree } =
		useAssistantFreeChat()

	useResponseInterceptor({
		onError: (error: AxiosError<any>) => {
			let status = error.response?.status || 0
			const response = error.response

			// TODO: Refactor this
			if (response?.data?.path?.includes('auth/refresh')) {
				status = 403
			}
			if ([403, 500].includes(status)) {
				const data = response?.data
				const needLogin = data.message.match(
					/Access Denied|Token has expired|refresh_token|Refresh Token|Token's Signature/gi
				)
				if (needLogin) {
					sessionManager.endSession()
					navigate('/signin')
					return
				}
				throw error
			}
			throw error
		}
	})

	useEffect(() => {
		sendPageView(location.pathname)
	}, [location])

	return (
		<Routes>
			<Route
				path='/'
				element={
					<RecoverSessionRoute>
						<MakeLogin previousURL={previousURL} />
					</RecoverSessionRoute>
				}
			/>
			<Route
				path='/signin'
				element={
					<RecoverSessionRoute>
						<MakeLogin previousURL={previousURL} />
					</RecoverSessionRoute>
				}
			/>
			<Route path='/import' element={<ExternalTokenCallback />} />
			<Route path='/email-validation'>
				<Route index element={<EmailValidation />} />
				<Route path='send' element={<SendEmail />} />
			</Route>
			<Route
				path='/signup'
				element={
					<RecoverSessionRoute>
						<PageSignup previousURL={previousURL} />
					</RecoverSessionRoute>
				}
			/>
			<Route
				path='/auth/callback'
				element={
					<RecoverSessionRoute>
						<CallBackProvider />
					</RecoverSessionRoute>
				}
			/>
			<Route
				path='/dashboard/*'
				element={
					<PrivateRoute>
						<AssistantChatFreeModal
							assistantId={null}
							isOpen={isOpenAssistantChatFree}
							onClose={onCloseAssistantChatFree}
						/>
						<DashboardLayout />
					</PrivateRoute>
				}
			>
				<Route
					index
					element={
						<Loading>
							<Home />
						</Loading>
					}
				/>

				<Route path='assistants/*' element={<AssistantsRoutes />} />

				<Route
					path='applets'
					element={
						<Loading>
							<Applets />
						</Loading>
					}
				/>

				<Route path='journeys/*' element={<JourneysRoute />} />
				<Route path='tools/*' element={<ToolsRoutes />} />
				<Route path='labs/*' element={<LabsRoutes />} />
				<Route path='profile/*' element={<ProfileRoute />} />
				<Route path='view/*' element={<DashboardRoutes />} />

				<Route
					path='settings'
					element={
						<Loading>
							<Settings />
						</Loading>
					}
				/>
				<Route
					path='public-link/:invite'
					element={
						<Loading>
							<PublicLink />
						</Loading>
					}
				/>
				<Route
					path='*'
					element={
						<Loading>
							<NonAccessibleJourney />
						</Loading>
					}
				/>
			</Route>
			<Route
				path='/journey'
				element={
					<PrivateRoute>
						<EditorLayout />
					</PrivateRoute>
				}
			>
				<Route path=':id'>
					<Route index element={<MapRedirect />} />
					<Route path='map'>
						<Route path=':mapId' element={<JourneyEditor />}>
							<Route path='point'>
								<Route path=':pointId' element={<PointDetail />} />
							</Route>
						</Route>
					</Route>
				</Route>
			</Route>
			<Route
				path='/content-view'
				element={
					<PrivateRoute>
						<Loading>
							<CompatibleRoutes />
						</Loading>
					</PrivateRoute>
				}
			/>
			<Route
				path={'*'}
				element={
					<Loading>
						<NonAccessibleJourney />
					</Loading>
				}
			/>
			{/* This component is used to direct the URLs of the Silvio book, it cannot be deleted. */}
			<Route path={'/dashboard/project/*'} element={<V1Redirect />} />
			<Route
				path={'/term'}
				element={
					<Loading>
						<TermsPage />
					</Loading>
				}
			/>
		</Routes>
	)
}
