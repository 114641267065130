import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import {
	Box,
	Button,
	Heading,
	Image,
	Spinner,
	Text,
	VStack
} from '@chakra-ui/react'

import { FullPageLayout } from 'modules/common/templates'
import SendIcon from 'modules/map/components/chat/send.svg'
import UsersApi from 'modules/user/usecases'
import { sendEmailValidation } from 'modules/user/usecases/send-email-validation'

interface TimerState {
	minutes: number
	seconds: number
}

export const SendEmail = () => {
	const { t } = useTranslation()
	const [timer, setTimer] = useState<TimerState>({ minutes: 3, seconds: 0 })
	const [showClock, setShowClock] = useState(true)
	const [email, setEmail] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [verified, setVerified] = useState<boolean | null>(null)

	const sendEmail = async () => {
		setIsLoading(true)
		try {
			const { email } = await sendEmailValidation()
			setEmail(email)
		} catch (error) {
			console.error('Error sending email:', error)
		} finally {
			setIsLoading(false)
		}
	}

	const resendEmail = () => {
		setTimer({ minutes: 3, seconds: 0 })
		setShowClock(true)
		sendEmail()
	}

	useEffect(() => {
		const verificationChannel = new BroadcastChannel('email_verification')

		UsersApi.getMe().then((user) => {
			if (user?.email_validated) {
				setVerified(true)
				return
			}

			setVerified(false)
			sendEmail()

			verificationChannel.onmessage = (event) => {
				if (event.data.verified) {
					setVerified(true)
				}
			}
		})

		return () => {
			verificationChannel.close()
		}
	}, [])

	useEffect(() => {
		if (!showClock) return

		const myInterval = setInterval(() => {
			setTimer((prevTimer) => {
				const { minutes, seconds } = prevTimer

				if (seconds === 0 && minutes === 0) {
					setShowClock(false)
					return prevTimer
				}

				if (seconds === 0) {
					return { minutes: minutes - 1, seconds: 59 }
				}

				return { ...prevTimer, seconds: seconds - 1 }
			})
		}, 1000)

		return () => clearInterval(myInterval)
	}, [showClock])

	const formattedSeconds =
		timer.seconds < 10 ? `0${timer.seconds}` : timer.seconds

	if (verified === null) {
		return (
			<FullPageLayout logoAsLink={false}>
				<Box
					display='flex'
					justifyContent='center'
					alignItems='center'
					height='200px'
				>
					<Spinner size='xl' color='custom_scheme_color_button' />
				</Box>
			</FullPageLayout>
		)
	}

	return (
		<FullPageLayout logoAsLink={false}>
			<Box display='flex' flexDirection='column' alignItems='center' p={[6, 7]}>
				<Heading mb={4} fontSize={['lg', 'xl', '2xl']} fontWeight='extrabold'>
					{verified
						? t('common:emailConfirmation.success')
						: t('common:emailConfirmation.title')}
				</Heading>
				{verified ? (
					<VStack gap={2} textAlign='center' fontWeight='medium' fontSize='md'>
						<Text>{t('common:emailConfirmation.verified')}</Text>
						<Text>{t('common:emailConfirmation.verifiedMessage')}</Text>
					</VStack>
				) : (
					<VStack>
						<Text textAlign='center' fontWeight='medium' fontSize='md'>
							<Trans
								i18nKey='common:emailConfirmation.message'
								values={{ email }}
								components={[<strong />]}
							/>
						</Text>
						<Text mt={2} fontSize='xs'>
							{showClock && (
								<Trans
									i18nKey='common:emailConfirmation.notReceive'
									values={{
										time: `${timer.minutes}:${formattedSeconds}`
									}}
									components={[<strong />]}
								/>
							)}
						</Text>
						<Button
							isDisabled={showClock}
							isLoading={isLoading}
							mt={3}
							leftIcon={<Image src={SendIcon} />}
							width='full'
							colorScheme='custom_scheme_color_button'
							onClick={resendEmail}
						>
							{t('buttons:sendConfirmationEmail')}
						</Button>
					</VStack>
				)}
			</Box>
		</FullPageLayout>
	)
}
