import { ReactNode, Suspense, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useNavigate } from 'react-router-dom'

import {
	Box,
	Button,
	Container,
	Drawer,
	DrawerContent,
	DrawerOverlay,
	HStack,
	Icon,
	IconButton,
	Text,
	useBreakpointValue,
	useDisclosure,
	useTheme,
	useToast
} from '@chakra-ui/react'

import { HamburgerIcon } from '@chakra-ui/icons'

import { BuyCreditsContent } from 'modules/common/components/credits/buy-credits'
import { IconStCoin } from 'modules/common/components/icons'
import { NavBar } from 'modules/common/components/layout'
import Loading from 'modules/common/components/loading'
import {
	DashboardContext,
	DashboardProvider
} from 'modules/common/contexts/dashboard-context'
import { useTenant } from 'modules/common/contexts/global-tenant-context'
import { useTypeUser } from 'modules/common/contexts/user-type-context'
import { useMe } from 'modules/user/hooks'

type Props = {
	children: ReactNode
}

const DashboardContainer = ({ children }: Props) => {
	const { sidebar } = useContext(DashboardContext)
	const isMobile = useBreakpointValue({ base: true, lg: false })
	return (
		<Box
			minH='full'
			bg='gray.50'
			pt={{ base: 0, lg: 14 }}
			pl={{ base: 0, lg: sidebar.width }}
			transition='0.3s ease-out'
		>
			<Container w='container.xl' maxWidth={isMobile ? '98%' : '90%'} pb={20}>
				{isMobile && <Header />}
				{children}
			</Container>
		</Box>
	)
}

const DashboardLayout = () => {
	return (
		<DashboardProvider>
			<Box position='fixed' top='0' left='0' h='100%'>
				<Sidebar />
			</Box>

			<DashboardContainer>
				<Outlet />
			</DashboardContainer>
		</DashboardProvider>
	)
}

const Sidebar = () => {
	const { showNav, setShowNav, sidebar, shrink } = useContext(DashboardContext)
	const renderAsDrawer = useBreakpointValue({ base: true, lg: false })

	if (renderAsDrawer) {
		return (
			<Drawer isOpen={showNav} onClose={setShowNav.off} placement='left'>
				<DrawerOverlay />
				<DrawerContent background='none'>
					<NavBar handleClick={setShowNav.off} />
				</DrawerContent>
			</Drawer>
		)
	}

	return (
		<Box
			as='aside'
			w={sidebar.width}
			h='inherit'
			display={{ base: 'none', lg: 'flex' }}
			transitionDuration='normal'
			transitionProperty='width'
		>
			<NavBar isIconMode={shrink} />
		</Box>
	)
}

const Header = () => {
	const { setShowNav } = useContext(DashboardContext)
	const { t } = useTranslation()
	const { user } = useMe()
	const theme = useTheme()
	const { hasPermission } = useTypeUser()
	const { tenant, isTenantDefault } = useTenant()
	const disclosure = useDisclosure()
	const navigate = useNavigate()
	const toast = useToast()

	const validateUserToast = () => {
		return toast({
			title: t('common:emailConfirmation.toastTitle'),
			description: (
				<HStack>
					<Text>
						<Button
							textDecoration='underline'
							color='white'
							variant='link'
							onClick={() => navigate('settings')}
						>
							{t('buttons:clickHere')}
						</Button>{' '}
						{t('common:emailConfirmation.toastDescription')}
					</Text>
				</HStack>
			),
			status: 'warning',
			isClosable: true
		})
	}

	const UserRedirect = () => {
		if (
			isTenantDefault(tenant) &&
			hasPermission('coin_enable_routing_to_credits') &&
			user?.email_validated
		) {
			navigate('/dashboard/labs/my/credits')
			return
		} else if (!isTenantDefault(tenant) && user?.email_validated) {
			disclosure.onOpen()
			return
		}
		validateUserToast()
	}

	return (
		<HStack justify='space-between' py={4} as='header' alignItems='center'>
			<IconButton
				icon={<Icon as={HamburgerIcon} />}
				aria-label='Abrir Menu'
				onClick={setShowNav.on}
				_focus={{ boxShadow: `0 0 0 2px ${theme.colors.primary.dark}` }}
			/>
			<HStack onClick={UserRedirect}>
				<Icon w={6} h={6} as={IconStCoin} />
				<Text fontWeight='semibold' fontSize='xl'>
					{user?.credits}
				</Text>
			</HStack>
			<BuyCreditsContent {...disclosure} />
		</HStack>
	)
}

export const EditorLayout = () => {
	return (
		<DashboardProvider>
			<Box w='full' h='100%' display='flex' background='gray.100'>
				<Sidebar />
				<Box display='flex' flex={1}>
					<Suspense fallback={<Loading />}>
						<Outlet />
					</Suspense>
				</Box>
			</Box>
		</DashboardProvider>
	)
}

export default DashboardLayout
